import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actionCreators from "../../store/ducks";
import App from "../../App";

function mapStateToProps(state) {
  return {
    metadataUploadResp: state.metadataUploadResp
  };
}

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);

export default AppContainer;
