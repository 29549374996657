import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { metadataUploadResp } from "./metadata/reducers";

// EXPORT ALL ACTIONS ////////////////////////////////////////
export * from "./metadata/actions";

export const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    metadataUploadResp
  });

export default createRootReducer;
