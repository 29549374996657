import { POST } from "../api";

const API_ENDPOINT = "/fedreg/metadatavalidator/api";

export const uploadMetadataApi = ({
  federationId = "skolfed-trial",
  formData,
  fedType
}) => {
  const url = `${API_ENDPOINT}/${fedType}/federation/${federationId}/validate`;
  return fetch(url, {
    method: POST,
    body: formData,
    headers: headers()
  })
    .then(resp => {
      if (resp) {
        if (resp.ok && (resp.status === 200 || resp.status === 204)) {
          return { resp };
        } else if (resp.status === 403) {
          window.location.href = "/#/error/";
          return {
            error: { statusCode: 403, statusMessage: "Not authorized" }
          };
        } else if (resp.status === 401) {
          return {
            error: {
              statusCode: resp.status,
              statusMessage: resp.statusText
            }
          };
        } else if (resp.json) {
          return resp.json().then(({ message }) => {
            const m = {
              error: {
                statusCode: resp.status,
                statusMessage: resp.statusText,
                message
              }
            };
            console.error("RESP ERROR with message", m);
            return m;
          });
        } else {
          console.error("RESP ERROR", resp);
          return {
            error: { statusCode: resp.status, statusMessage: resp.statusText }
          };
        }
      }
    })
    .catch(error => {
      console.error("ERROR CATCH", error);
      return {
        error: { statusCode: 503, statusMessage: "Service Unavailable" }
      };
    });
};
const headers = () => {};
