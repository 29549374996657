import { call, put } from "redux-saga/effects";

import { uploadMetadataApi } from "./api";

import {
  uploadMetadataSuccess,
  uploadMetadataFailure
} from "../../ducks/metadata/actions";

const logError = (error, type) =>
  console.info(`api -> saga -> ${type} -> error:`, error);

// UPLOAD_METADATA
function* uploadMetadata(action) {
  const { federationId, formData, fedType } = action;
  const { resp, error } = yield call(uploadMetadataApi, {
    federationId,
    formData,
    fedType
  });
  if (resp) {
    const data = yield resp.json();
    yield put(
      uploadMetadataSuccess({
        data
      })
    );
  } else if (error) {
    logError("uploadMetadata", error);
    yield put(
      uploadMetadataFailure({
        error: yield error
      })
    );
  }
}

export { uploadMetadata };
