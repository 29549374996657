import { takeLatest } from "redux-saga/effects";

//METADATA
import { UPLOAD_METADATA_REQUEST } from "../ducks/metadata/actionTypes";

import { uploadMetadata } from "./metadata/metadata";

export default function* rootSaga() {
  yield takeLatest(UPLOAD_METADATA_REQUEST, uploadMetadata);
}
