import React, { Component } from "react";
import { Grid, Segment, List } from "semantic-ui-react";
import { MetaDataValidatorView } from "./modules/upload/MetaDataValidatorView";
import { AppContext } from "./index";
import { IISLogo } from "components/lib/logo";
import "filepond/dist/filepond.min.css";
import "./App.css";

class App extends Component {
  render() {
    return (
      <div className="site">
        <AppContext.Consumer>
          {ctx => (
            <React.Fragment>
              <Grid
                className="site-content"
                padded={true}
                stackable={true}
                columns={3}
              >
                <Grid.Column width="2" />
                <Grid.Column width="12">
                  <MetaDataValidatorView
                    federationId={ctx.federationId}
                    fedType={ctx.fedType}
                    {...this.props}
                  />
                </Grid.Column>
                <Grid.Column width="2" floated="right" />
              </Grid>
              <Footer
                federationId={ctx.federationId}
                integrityPolicy={ctx.integrityPolicy}
                address={ctx.address}
                addressLink={ctx.addressLink}
                mail={ctx.mail}
                inverted={ctx.inverted}
              />
            </React.Fragment>
          )}
        </AppContext.Consumer>
      </div>
    );
  }
}

const Footer = ({
  inverted = false,
  integrityPolicy = "https://www.skolfederation.se/om/integritetspolicy/",
  addressLink = "https://www.skolfederation.se",
  address = "www.skolfederation.se",
  mail = "info@skolfederation.se"
}) => (
  <Segment
    inverted={inverted}
    className="footer"
    vertical
    style={{ position: "absolute", bottom: "0", width: "100%" }}
  >
    <Grid padded stackable columns={1}>
      <Grid.Row>
        <Grid.Column width={2} />
        <Grid.Column
          width={12}
          style={{
            marginLeft: "auto",
            marginRight: "auto"
          }}
        >
          <List
            inverted={inverted}
            horizontal
            divided
            link
            style={{ width: "100%", textAlign: "center" }}
            verticalAlign="middle"
          >
            <List.Item as="a" href={integrityPolicy} target="blank">
              Integritetspolicy
            </List.Item>
            <List.Item as="a" href={`mailto:${mail}`}>
              {mail}
            </List.Item>
            <List.Item as="a" href={addressLink} target="blank">
              {address}
            </List.Item>
            <List.Item
              as="a"
              href="https://internetstiftelsen.se/"
              target="_blank"
            >
              <List.Content
                verticalAlign="middle"
                content={<IISLogo size="tiny" verticalAlign="middle" />}
              />
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column width={2} />
      </Grid.Row>
    </Grid>
  </Segment>
);

export default App;
