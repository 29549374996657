import { createHashHistory } from "history";
import { applyMiddleware, createStore, compose } from "redux";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { logger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import { createRootReducer } from "./ducks";
import rootSaga from "./sagas";

const initialState = {};
const enhancers = [];
const history = createHashHistory();
const sagaMiddleware = createSagaMiddleware();
const middleware = [routerMiddleware(history), logger, sagaMiddleware];

if (process.env.NODE_ENV === "development") {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }
}

const rootReducer = createRootReducer(history);
const store = createStore(
  connectRouter(history)(rootReducer),
  initialState,
  compose(applyMiddleware(...middleware), ...enhancers)
);

sagaMiddleware.run(rootSaga);

export { history, store };
