export const initConfig = async () => {
  if (process.env.NODE_ENV === "production") {
    const resp = await fetch("/config.json");
    const data = await resp.json();
    return mapDataToConfig({ data });
  } else {
    const resp = await fetch("/config-dev.json");
    const data = await resp.json();
    return mapDataToConfig({ data });
  }
};

const mapDataToConfig = ({ data }) => {
  return {
    federationId: data["federationId"],
    fedType: data["fedType"],
    title: data["title"],
    integrityPolicy: data["integrityPolicy"],
    addressLink: data["addressLink"],
    address: data["address"],
    mail: data["mail"],
    favicon: data["favicon"],
    theme: data["theme"],
    inverted: data["inverted"]
  };
};

export default initConfig;
