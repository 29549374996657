export const createSuccessReducer = (state, action) => {
  return { ...state, ...{ created: true, ...action } };
};

export const createFailureReducer = (state, action) => {
  return { ...state, ...{ created: false, ...action } };
};

export const createSuccessReducerById = (state, action) => {
  return { ...state, ...{ created: true, ...action } };
};
export const createFailureReducerById = (state, action) => {
  return { ...state, ...{ created: false, ...action } };
};

export const loadSuccessReducer = (state, action) => {
  return { ...state, ...{ fetched: true, ...action } };
};

export const loadFailureReducer = (state, action) => {
  return { ...state, ...{ fetched: false, ...action } };
};

export const loadSuccessReducerById = (state, action) => {
  return {
    ...state,
    ...{ fetched: true, ...action }
  };
};

export const loadFailureReducerById = (state, action) => {
  return {
    ...state,
    ...{ fetched: false, ...action }
  };
};

export const updateSuccessReducerById = (state, action) => {
  return { ...state, ...{ updated: true, ...action } };
};

export const updateFailureReducerById = (state, action) => {
  return { ...state, ...{ updated: false, ...action } };
};

export const deleteSuccessReducerById = (state, action) => {
  return { ...state, ...{ deleted: true, ...action } };
};

export const deleteFailureReducerById = (state, action) => {
  return { ...state, ...{ deleted: false, ...action } };
};
