import {
  UPLOAD_METADATA_REQUEST,
  UPLOAD_METADATA_SUCCESS,
  UPLOAD_METADATA_FAILURE
} from "./actionTypes";

///////////////////////////////////////////////////////////////////////////////
// Upload metadata action
///////////////////////////////////////////////////////////////////////////////
export const uploadMetadataRequest = ({
  federationId,
  formData,
  fedType,
  publishFile
}) => {
  return {
    type: UPLOAD_METADATA_REQUEST,
    federationId: federationId,
    fedType: fedType,
    formData: formData,
    publishFile: publishFile
  };
};

export const uploadMetadataSuccess = ({ data }) => {
  return {
    type: UPLOAD_METADATA_SUCCESS,
    data
  };
};

export const uploadMetadataFailure = ({ error }) => {
  return {
    type: UPLOAD_METADATA_FAILURE,
    error
  };
};
