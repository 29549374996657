import {
  UPLOAD_METADATA_SUCCESS,
  UPLOAD_METADATA_FAILURE
} from "./actionTypes";

import {
  updateSuccessReducerById,
  updateFailureReducerById
} from "../common/reducer";

export const metadataUploadResp = (state = {}, action) => {
  switch (action.type) {
    case UPLOAD_METADATA_SUCCESS:
      return updateSuccessReducerById(state, action);
    case UPLOAD_METADATA_FAILURE:
      return updateFailureReducerById(state, action);
    default:
      return state;
  }
};
