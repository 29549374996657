import React from "react";
import { createRoot } from 'react-dom/client';
import "./index.css";
import { MainRouts } from "./routs";
import * as serviceWorker from "./serviceWorker";

import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { HashRouter as Router } from "react-router-dom";
import "semantic-ui-css/semantic.min.css";

import store, { history } from "./store";
import initConfig from "./config";
export const AppContext = React.createContext();

const container = document.getElementById('root');
const root = createRoot(container);

initConfig().then(config => {
  const { title, favicon } = config;
  const head = document.getElementsByTagName("head")[0];
  head.getElementsByTagName("title")[0].innerHTML = title;
  const updateFavicon = document.getElementById("favicon");
  updateFavicon.setAttribute("type", "image/png");
  updateFavicon.setAttribute("href", favicon);

  root.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Router>
          <AppContext.Provider value={config}>
            <MainRouts />
          </AppContext.Provider>
        </Router>
      </ConnectedRouter>
    </Provider>,
  );
});
serviceWorker.unregister();
export default AppContext;
